
import Cart from './Cart'
import ProductList from './ProductsList'

export default function MosaicShop(){
	return(
		<div>

			<ProductList /> 
			<Cart /> 	

		</div>
	)
}

